<template>
    <div>
        <div>
          <a-button class="mb-2" :to="{ name: 'courses' }">
            Regresar
          </a-button>
        </div>
        <a-table :loading="loading" :source="courses" :columns="columns">
            <template #td(actions)="{ item }">
                <a-dropdown>
                    <template #menu>
                        <a-dropdown-item :to="{ name: 'courses.assign', params: { id: item.id } }">
                            Asignar
                        </a-dropdown-item>
                        <a-dropdown-item :to="{ name: 'courses.create', query: { update: item.id } }">
                            Actualizar
                        </a-dropdown-item>
                        <a-dropdown-item @click="remove(item.id)">
                            Eliminar
                        </a-dropdown-item>
                    </template>
                </a-dropdown>
            </template>
        </a-table>
    </div>
</template>

<script>
export default {
    data: () => ({
        columns: [
            { title: 'título', key: 'title' },
            { title: 'código', key: 'code' },
            { title: 'acciones', slot: 'actions' }
        ],
        courses: [],
        loading: false
    }),
    methods: {
        fetch() {
            this.$repository.courses
            .index({ limit: 0, with: 'semesters' })
            .then(({ data }) => {
                this.courses = data.filter($0 => !$0.semesters.length)
            })
            ._loading(() => this.loading = !this.loading)
        },
        remove(id) {
            this.$confirm({
                message: `¿Desea eliminar esta materia?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.courses
                            .delete(id)
                            .then(() => this.fetch())
                    }
                }
            })
        }
    },
    mounted() {
        this.fetch()
    }
}

</script>
