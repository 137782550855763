<template>
    <div>
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <div class="flex">
            <div class="w-full">
                <form @submit.prevent="isUpdating ? update(course) : store(course)" @keydown="removeError($event.target.id)">
                    <h3 class="my-4 text-2xl">{{ isUpdating ? 'Actualizar' : 'Crear' }} materia</h3>
                    <a-card>
                        <div class="flex flex-wrap -mx-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                <a-input
                                    label="Código"
                                    required
                                    class="mb-4"
                                    id="code"
                                    :error="error('code')"
                                    v-model="course.code" />

                                <a-input
                                    label="Título"
                                    required
                                    class="mb-4"
                                    id="title"
                                    :error="error('title')"
                                    v-model="course.title" />

                                <a-input
                                    type="textarea"
                                    label="Contenido Programático"
                                    required
                                    class="mb-4"
                                    id="scheduled_content"
                                    :error="error('scheduled_content')"
                                    v-model="course.scheduled_content" />

                                <a-upload-input
                                    label="Recurso del contenido programado (Opcional)"
                                    class="mb-4"
                                    id="scheduled_content_resource"
                                    :error="error('scheduled_content_resource')"
                                    v-model="course.scheduled_content_resource"/>

                                <a-upload-input
                                    label="Recurso de contenido analítico (Opcional)"
                                    class="mb-4"
                                    id="analytic_content_resource"
                                    :error="error('analytic_content_resource')"
                                    v-model="course.analytic_content_resource"/>

                                <a-upload-input
                                    label="Recurso de contenido sinóptico (Opcional)"
                                    class="mb-4"
                                    id="synoptic_content_resource"
                                    :error="error('synoptic_content_resource')"
                                    v-model="course.synoptic_content_resource"/>

                                <a-input
                                    label="Créditos"
                                    required
                                    class="mb-4"
                                    id="credits"
                                    type="number"
                                    :error="error('credits')"
                                    v-model.number="course.credits" />

                                <a-input
                                    label="Horas"
                                    required
                                    class="mb-4"
                                    id="hours"
                                    type="number"
                                    :error="error('hours')"
                                    v-model.number="course.hours" />
                            </div>

                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">


                              <label class="mb-2 block font-medium text-gray-700" for="calculated_avg">¿Calcula promedio?</label>
                              <a-switch class="mb-4" id="calculated_avg" v-model="course.calculate_avg" />


                              <label class="mb-2 block font-medium text-gray-700" for="elective">¿Es electiva?</label>
                              <a-switch class="mb-4" id="elective" v-model="course.elective" />


                              <label class="mb-2 block font-medium text-gray-700" for="enabled">¿Esta activa?</label>
                              <a-switch class="mb-4" id="enabled" v-model="course.enabled" />

                              <label class="mb-2 inline-block font-medium text-gray-700" for="final_delivery" v-tooltip="`Puede tener una entrega final el mismo día y hora que otra sección`">¿Multientrega?</label>
                              <a-switch class="mb-4" id="final_delivery" v-model="course.final_delivery_merged_sections" />

<!--                            <label class="mb-2 block font-medium text-gray-700" for="final_delivery">¿Tiene entrega final?</label>-->
<!--                            <a-switch class="mb-4" id="final_delivery" v-model="course.final_delivery" />-->

<!--                                <template v-if="course.final_delivery">-->
<!--                                    <a-input-->
<!--                                        label="Porcentaje del valor final"-->
<!--                                        required-->
<!--                                        class="mb-4"-->
<!--                                        id="final_delivery_value_percent"-->
<!--                                        type="number"-->
<!--                                        :error="error('final_delivery_value_percent')"-->
<!--                                        v-model.number="course.final_delivery_value_percent" />-->
<!--                                  -->
<!--                                </template>-->
                            </div>
                        </div>

                        <template #footer>
                            <a-button class="mr-2" type="submit" :loading="loading">
                                {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
                            </a-button>

                            <a-button outlined :to="{ name: 'courses' }">
                                Cancelar
                            </a-button>
                        </template>
                    </a-card>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        course: {
            code: '',
            title: '',
            scheduled_content: '',
            scheduled_content_resource: null,
            analytic_content_resource: null,
            synoptic_content_resource: null,
            credits: 0,
            hours: '',
            priority: '',
            calculated_avg: '',
            elective: false,
            enabled: false,
            calculated_avg_boolean: false,
            // final_delivery: false,
            // final_delivery_value_percent: '',
            final_delivery_merged_sections: false
        },
        isUpdating: false
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            courses: state => state.courses.pagination.data,
            currentCourseOnState: state => state.courses.course,
            loading: state => state.courses.loading
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchCourse: 'courses/show',
            store: 'courses/store',
            update: 'courses/update',
            removeError: 'removeError',
        }),
        handleFileSelection(event) {
            this.course.scheduled_content_resource = event.target.files[0]
            this.removeError(event.target.id)
        }
    },
    watch: {
        currentCourseOnState(val) {
            this.course = {...val}
        }
    },
    created() {
        const id = this.$route.query.update
        this.setNavbarTitle(['Listado de materias', 'Crear materia'])
        if (id) {
            this.isUpdating = true

            const found = this.courses.find(course => course.id === parseInt(id))

            if (found) {
                this.course = {...found}
                this.setNavbarTitle(['Listado de materias', found.title, 'Editar materia'])
            } else {
                this.fetchCourse({
                    id,
                    filters: {}
                })
            }
        }
    }
}
</script>
